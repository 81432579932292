import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [error, setError] = useState<Error | undefined>(undefined);
  const [word, setWord] = useState("");

  const refreshWord = () => {
    fetch("https://words-api.onrender.com/")
    // fetch("http://localhost:8000/")
      .then(res => res.json())
      .then(
        (result) => {
          setWord(result.word)
        },
        (error: Error) => {
          setError(error);
        }
      )
  }

  useEffect(() => {
    refreshWord();
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        {error ?
          <p className="App-error">{error.name}: {error.message}</p> : <p className='App-word'>{word}</p>
        }
        <button className='App-button' onClick={refreshWord}>
          🔀
        </button>
      </header>
    </div>
  );
}

export default App;
